.rotate-180 {
    transform: rotate(180deg);
  }
  
  /* Target the entire scrollbar */
::-webkit-scrollbar {
  width: 8px;  /* Width of the vertical scrollbar */
  height: 8px; /* Height of the horizontal scrollbar */
}

/* Track of the scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #d2d2d2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b5b4b4;
}

.dark ::-webkit-scrollbar-track {
  background: #182238;
}

/* Handle of the scrollbar */
.dark ::-webkit-scrollbar-thumb {
  background: #2b313f;
}

/* Handle on hover */
.dark ::-webkit-scrollbar-thumb:hover {
  background: #242934;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.apexcharts-tooltip {
  background: #CBD5E1 !important;
  color: #11151b !important;
  border-color: #CBD5E1 !important;
}

.dark .apexcharts-tooltip {
  background: #1E293B !important;
  color: #94A3B8 !important;
  border-color: #182238 !important;
}

.dark .apexcharts-tooltip .apexcharts-tooltip-title {
  background: #192231 !important; /* Change the background color of the tooltip header */
  color: #94A3B8 !important; /* Change the text color of the tooltip header */
}

.apexcharts-tooltip .apexcharts-tooltip-title {
  background: #b6bec9 !important; /* Change the background color of the tooltip header */
  color: #11151b !important; /* Change the text color of the tooltip header */
}