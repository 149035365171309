.toggleIcon{
  display: none;
}

.base-modal {
  z-index: 1000;
}

.investorGradient {
  background: linear-gradient(to right, #103C63, #4277AB);
}

.label{
  font-size: 14px;
  font-weight: 400;
  color: #181818;
}
.dark .label{
  color: #94A3B8 
}



.gradient-background {
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, #1a1a1a, transparent);
}

.background-image {
  /* width: 500px; */
  /* height: 500px; */
  background-image: url('../assets/backgroundGradient.svg');
  background-size: contain; /* Adjusts the background image to cover the entire div */
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Ensures the background image does not repeat */
}

.join-us-container {
  /* text-align: center; */
  display: flex;
  align-items: center;
}

.cardBtnGradient{
  background: linear-gradient(180deg, #FFAA36 100%, #FFB34B 100%);
}

.user-profile {
  width: 40px;
  height: 40px;
  /* border: 2px solid #fff; */
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin-left: -5px;
  z-index: 1;
}

.user-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile:nth-child(2) {
  margin-left: -5px;
}

.user-profile:nth-child(3) {
  margin-left: -5px;
}



/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.gradient-bg {
  background: linear-gradient(90deg, #FB4644 0%, #CFC51B 50%, #07FE82 100%);
}

.dark .inset-shadows {
  box-shadow: inset -100px 0 15px -10px rgba(40, 44, 63, 0.12);
}

.inset-shadows {
  box-shadow: inset -100px 0 15px -10px rgba(199, 199, 199, 0.12);
}

.gradient-text {
  background: linear-gradient(
      90deg,
      rgba(255, 60, 17, 1) 0%,
      rgba(233, 184, 39, 1) 50%,
      rgba(34, 197, 94, 1) 100%
  );
  color: black;
  text-align: center; /* Optional: Center align the text */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/* .sideBarSize {

  transform: translateX(-100%);
  transition: transform 0.5s ease;
} */


@media only screen and (min-width:767px) {
  .showOnMobile{
    display: none !important;
  }
  
}





@media only screen and (min-width: 1701px) {
    .sideBarSize {
      width: 15%;
    }
    .mainPageSize {
        width: 85%;
      }
}

@media only screen and (max-width:2000px){
  .smallCardContainer{
    width: 24% !important;
  }
}

@media only screen and (max-width: 1700px) {
    .sideBarSize {
      width: 17%;
    }
    .mainPageSize {
        width: 83%;
      }
   
  }
  
  @media only screen and (max-width: 1600px) {
    .sideBarSize {
      width: 17%;
    }
    .mainPageSize {
        width: 83%;
      }
   
  }

  @media only screen and (max-width:1484px){
    .smallCardContainer{
      width: 32% !important;
    }
  }
  
  @media only screen and (max-width: 1450px) {
    .sideBarSize {
      width: 19%;
    }
    .mainPageSize {
        width: 81%;
      }
   
  }

  /* @media only screen and (max-width:1350px){
    .mainContainer{
      width: 60vw !important;
    }
  } */
  
  @media only screen and (max-width: 1300px) {
    .sideBarSize {
      width: 21%;
    }
    .mainPageSize {
        width: 79%;
      }
      .searchBar{
        font-size: 13px !important;
        padding: 5px 4px 5px 4px !important;
        
        }
        .searchBarGap{
            padding-right: 10px !important;
        }

        .compareSymbolHeader{
          flex-direction: column !important;
          align-items: flex-start !important;
        }

        .compareSymbolsArea{
          width: 100% !important;
        }
        .comparePerios{
          width: 100% !important;
          justify-content: end !important;
          margin-top: 1rem;
        }
        .compareChart{
          height: 80% !important;
        }
  }

  @media only screen and (max-width: 1240px) {
    .companyText{
      font-size: 0.9rem;
    }
    .priceText{
      font-size: 0.9rem;
    }
    .changeText{
      font-size: 0.6rem;
    }
  }

  @media only screen and (max-width: 1190px) {
    .sideBarSize {
      width: 23%;
    }
    .mainPageSize {
        width: 77%;
      }

      .cardMainContainer{
        flex-direction: column;
        justify-content: center;
      }
      .cardSubContainer{
        width: 100% !important;
      }
      .largeText{
        font-size: 18px !important;
      }
      .mediumText{
        font-size: 14px !important; 
      }

  }


  @media only screen and (max-width:1101px) {
    .smallCardContainer{
      width: 31% !important;
    }
  }
  

 
  @media only screen and (max-width: 1090px) {
    .sideBarSize {
      width: 24.5%;
    }
    .mainPageSize {
        width: 75.5%;
      }
      .smallCardContainer{
        width: 48.5% !important;
      }
  
  }



  @media only screen and (max-width: 1008px) {
    .sideBarSize {
      width: 26.5%;
    }
    .mainPageSize {
        width: 73.5%;
      }

      /* .mainContainer{
        width: 80vw !important;
      }
    */
  }
  @media only screen and (max-width: 938px) {
    .sideBarSize {
      width: 28.5%;
    }
    .mainPageSize {
        width: 71.5%;
      }
  
  }
  
  @media only screen and (max-width: 890px) {
    .sideBarSize {
      width: 31%;
    }
    .mainPageSize {
        width: 69%;
      }
      .addToWatchlist{
        display: none !important;
      }
  }
  @media only screen and (max-width:843px) {
    .smallCardContainer{
      width: 100% !important;
    }
  }
  
  
  @media only screen and (max-width: 767px) {
    .mainPageSize {
        width: 100% !important;
        height: 100% !important;
      }
    .sideBarSize {
      display: none;
    }
    .searchCommand{
      display: none !important;
    }
   
    .toggleIcon{
      display: block;
    }
    .headerHeight {
        height: 17% !important;
        border: none !important;
      }
      .mainContainer{
        height: 83% !important;
        /* background-color: red !important; */
      }
      
      .mainSide {
        width: 100% !important;
        position: absolute !important;
        z-index: 9999 !important;
        background: #221f1f3b;
        backdrop-filter: blur(2.5px);
        height:100% !important;
      }
      .dark .mainSide {
        width: 100% !important;
        height:100% !important;
        z-index: 9999 !important;
        position: absolute !important;
        background: #221f1f3b !important;
        backdrop-filter: blur(2.5px);
      }
      .sideWidth {
        width: 40% !important;
        height: 100% !important;
        background: white !important;
      }
      .dark .sideWidth {
        background: #0F172A !important;
      }
      .crossSidebar {
        display: flex !important;
      }
 
      .sideBarOpen {
        transform: translateX(0);
        animation: slideIn 0.5s forwards;
      }
      
      .sideBarClose {
        transform: translateX(-100%);
        animation: slideOut 0.5s forwards;
      }
      .mainContainer{
        width: 100vw !important;
      }
      .smallCardContainer{
        width: 48% !important;
      }
  }

  @media only screen and (max-width:736px){
    .mainSettingContainer{
      width: 100% !important;
    }
    .settingLeftSide{
      display: none;
    }
    .settingRightSide{
      width: 25rem !important;
    }
    .settinIcon{
      display: block !important;
    }
  }
  
  @media only screen and (max-width:718px) {
    .sideWidth {
      width: 53% !important;

    }
   
  }


  @media only screen and (max-width:650px) {
   
    .searchBar{
      display: none !important;
    }
    .mobileSearch{
      display: block !important;
    }
    .paginationArea{
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
  }
  .paginatedContainer{
    padding-top: 1rem !important;
    text-align: center;
  }
  }

  @media only screen and (max-width:639px) {
    .tableShowing{
     text-align: center;
     padding: 5px 0px 2px 0px;
    }
   }
  
  @media only screen and (max-width:620px) {
    .smallCardContainer{
      width: 100% !important;
    }
   }
  
  @media only screen and (max-width:610px) {
    .portfolioTypeCard{
      width: 50% !important;
    
    }
    .linkBrokerageContainer{
      width: 100% !important;
      height: 50vh !important;
      overflow-y: auto;
    }
    .brokerageItem{
      width: 47% !important;
    }

    .manualPortfolioContainer{
      width: 100% !important;
    }
   
   }
  



  @media only screen and (max-width:550px) {
   .suggestionText{
    font-size: 10px !important;
   }
  }
 
  @media only screen and (max-width:500px) {
    .sideWidth {
      width: 80% !important;

    }
  }

  @media only screen and (max-width:450px){
 
      .pieText{
        font-size: 1.5rem !important;
        margin-bottom: 1rem !important;
      } 
      .cardSubContainer{
        height: 40vh !important;
      }
     
    
  }

  @media screen and (max-width: 424px) {
    .yourDataText{
      font-size: 14px;
    }
    
  }


  @media screen and (max-width: 400px) {
    .priceText{
      font-size: 0.7rem;
    }
    
  }

 


  @media screen and (max-width: 380px) {
    .pieText{
      font-size: 1rem !important;
    } 
  }

  @media screen and (max-width: 360px) {
    .priceText{
      font-size: 0.5rem;
    }
    .brokerageItem{
      width: 45% !important;
    }
    .mainBtnStyle{
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      font-size: 12px !important;
    }
    
  }