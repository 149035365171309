.dark .rbc-month-view {
    background-color: #0f172a;
    border-color: #1e2938;
    border-radius: 10px;
    overflow: hidden;
  }
  .dark .rbc-month-row {
    border-color: #1e2938;
  }

  .dark .rbc-day-bg {
    background-color: #0f172a;
    border-color: #1e2938;
  }


  .dark .rbc-row {
    border-color: #1e2938;
  }

  .dark .rbc-header{
    border-color: #1e2938;
    height: 46px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #141d31;
  }




  /* asdfasdfas */

  .rbc-month-view {
    /* background-color: #0f172a; */
    background-color: transparent;
    /* border-color: #1e2938; */
    border-color: #d9e7f9;
    border-radius: 10px;
    overflow: hidden;
  }
  .rbc-month-row {
    border-color: #d9e7f9;
  }

  .rbc-day-bg {
    /* background-color: #0f172a; */
    background-color: transparent;
    border-color: #d9e7f9;
  }


  .rbc-row {
    border-color: #d9e7f9;
  }

  .rbc-header{
    border-color: #d9e7f9;
    height: 46px;
    justify-content: center;
    align-items: center;
    display: flex;
    /* background-color: #141d31; */
    background-color: transparent;
  }